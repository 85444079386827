@tailwind base;
@tailwind components;
@tailwind utilities;

/* Assign font family "Source Sans Pro" by default */
body {
  font-family: "Source Sans Pro", sans-serif;
  height: 100vh;
}

#root {
  height: 100%;
}